import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import wrapPageElements from './wrap-page-element';
import wrapWithProviders from './wrap-with-providers';

export const onInitialClientRender = () => {
    const isSsr = typeof window === 'undefined';

    if (!isSsr) {
        const klav = (window as any).klaviyo;
        klav?.cookieDomain('blootropic.com').then((r) => {

        })
            .catch((e) => {
                console.error(e);
            });
    }
}

export const wrapPageElement = wrapPageElements

export const wrapRootElement = wrapWithProviders
