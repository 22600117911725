// import { useMediaQuery } from "@mui/material"
// import { useTheme } from "@mui/material/styles"
import React from 'react';

import styled from '@emotion/styled';
import { useTheme } from '@mui/material';

import { dynamicHeroHeight } from '../shared/styles/dynamicHeroHeight';
import { notPrintable } from '../shared/styles/notPrintable';

type Props = {
  bottomDirection?: "left" | "right"
  topDirection?: "left" | "right"
  gradientDirection?: "horizontal" | "vertical"
  leftColor?: string
  rightColor?: string
  id: string
  autoHeight?: boolean
  children?: React.ReactNode
  breakpointHeight?: number
  minHeroHeight?: number
}

export function HorizontalSlantContainer({
  bottomDirection,
  topDirection,
  leftColor,
  rightColor,
  gradientDirection,
  id,
  breakpointHeight,
  minHeroHeight,
  children,
  autoHeight,
}: Props) {
  const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  // const isTinyMobile = useMediaQuery(theme.breakpoints.down("md"))

  // let userAgent;
  // if (req) { // if you are on the server and you get a 'req' property from your context
  //   userAgent = req.headers['user-agent'] // get the user-agent from the headers
  // } else {
  //   userAgent = navigator.userAgent // if you are on the client you can access the navigator from the window object
  // }
  // let isMobile = Boolean(userAgent.match(
  //   /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  // ))

  const headerBackgroundLeftColor = leftColor || theme.palette.gradients.dark.start
  const headerBackgroundRightColor = rightColor || theme.palette.gradients.dark.end

  const style = {
    display: "block",
    width: "100%",
  }

  // const slantPercent = isTinyMobile ? 95 : isMobile ? 85 : 75
  const slantPercent = 100

  const linearGradientProps =
    !gradientDirection || gradientDirection == "horizontal"
      ? {
        x1: "0",
        y1: "100",
        x2: "100",
        y2: "100",
      }
      : {
        x1: "100",
        y1: "10",
        x2: "100",
        y2: "70",
      }

  const HeightDiv = React.useMemo(
    () => styled.div`
      ${dynamicHeroHeight({
      breakpointHeight: breakpointHeight,
      minHeroHeight: minHeroHeight,
      autoHeight: autoHeight,
    })}
    `,
    [breakpointHeight, minHeroHeight, autoHeight],
  )
  const HeightSvg = styled.svg`
    ${dynamicHeroHeight({
    breakpointHeight: breakpointHeight,
    minHeroHeight: minHeroHeight,
    autoHeight: autoHeight,
  })}
  `

  const NotPrintable = styled('div')(notPrintable);

  return (
    <HeightDiv
      style={{
        position: "relative",
      }}
    >
      <NotPrintable>
        <HeightDiv
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
          }}
        >
          <HeightSvg
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            fill="none"
          >
            <path
              d={`M0 ${topDirection === "left" ? 100 - slantPercent : "0"} L100 ${topDirection === "right" ? 100 - slantPercent : "0"
                } L100 ${bottomDirection === "right" ? slantPercent : "100"} L0 ${bottomDirection === "left" ? slantPercent : "100"
                } Z`}
              fill={`url(#sv_bg_${id})`}
            />
            <defs>
              <linearGradient
                id={`sv_bg_${id}`}
                {...linearGradientProps}
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={headerBackgroundLeftColor} />
                <stop offset="1" stopColor={headerBackgroundRightColor} />
              </linearGradient>
            </defs>
          </HeightSvg>
        </HeightDiv>
      </NotPrintable>
      <div
        style={{
          zIndex: 2,
          position: 'relative'
        }}
      >
        {children}
      </div>
    </HeightDiv >
  )
}
