import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { startCase } from 'lodash-es';

export function sortAlphabetically(a: any, b: any, propName: string): number {
  var textA = a[propName].toUpperCase();
  var textB = b[propName].toUpperCase();
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export function isBot() {
  if (typeof window === 'undefined') return false;
  return (navigator.userAgent.includes('Chrome-Lighthouse') || navigator.userAgent.includes('Googlebot'))
}

export function isTrackingEnabled() {
  if (typeof window === 'undefined') return false;
  return !isBot();
}

export const formatPrice = (currency, value) =>
  Intl.NumberFormat("en-US", {
    currency,
    minimumFractionDigits: 2,
    style: "currency",
  }).format(value)

export const getCurrencySymbol = (currency, locale = undefined) => {
  if (!currency) {
    return
  }

  const formatter = Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  })
  const parts = formatter.formatToParts(100)
  const { value: symbol } = parts.find((part) => part.type === "currency")
  const formatted = formatter.format(100)
  const symbolAtEnd = formatted.endsWith(symbol)
  return { symbol, symbolAtEnd }
}

export const antiSlugify = (slug: string) => {
  return startCase(slug.replace(/-/g, ' '))
}

type ContentfulLivePreviewData = RenderRichTextData<null> & {
  json: any
}

export const getContentfulLivePreviewDataOrFallback = (data: any) => {
  const typed = data as ContentfulLivePreviewData;
  return { raw: typed.json ? JSON.stringify(typed.json) : typed.raw }
}