import { Slice } from 'gatsby';
import React from 'react';

import { WindowLocation } from '@reach/router';

import Header from './header';
import Footer from './footer';

interface LayoutProps {
  children?: React.ReactNode
  location: WindowLocation<"state">
  isSiteFramed?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, location, isSiteFramed }) => {
  return (
    <>
      <Header location={location} isSiteFramed={isSiteFramed} />
      {children}
      <Footer location={location} isSiteFramed={isSiteFramed} />
    </>
  )
}

export default Layout
