import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';

import { Add, AddShoppingCart, Check, Delete, PlusOne, ShoppingBagOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Badge, Box, Button, Chip, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, useTheme
} from '@mui/material';
import {
  BuyNowButton, Image as ShopifyImage, ShopPayButton, useCart, useCartLine, useMoney
} from '@shopify/hydrogen-react';
import { MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

import { useCartStyling } from '../../pages/cart';
import { useAnalytics } from '../../shared/analytics';
import { antiSlugify } from '../../shared/utils';
import { NumericInput } from '../controls/numeric-input';

type AddToCartControlProps = {
  variantId: string;
  quantity: number;
  available: boolean;
  pageType: string
  product: Queries.ShopifyProductCardFragment
  withBuyNow?: boolean
  withShopPay?: boolean
}

export function AddToCart({ variantId, quantity, available, pageType, product, withBuyNow, withShopPay }: AddToCartControlProps) {
  const { status, linesAdd } = useCart();
  const loading = status === 'updating' || status === 'creating'

  const simple = !withBuyNow && !withShopPay;

  const [justAddedToCart, setJustAddedToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  useEffect(() => {
    // console.log('heree status', status)
    if (loading && location.pathname !== '/cart' && location.pathname !== '/cart/') {
      setJustAddedToCart(true);
      setAddedToCart(true);
      setTimeout(() => {
        setJustAddedToCart(false)
      }, 4000)
    }
  }, [status])

  const analytics = useAnalytics();

  function addToCart(e) {
    e.preventDefault()
    linesAdd([
      { quantity, merchandiseId: variantId }
    ])

    analytics.trackAddToCart(pageType,
      [
        {
          ...product
        }
      ]
      , variantId, quantity);
  }

  return (
    <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} spacing={2}>

      <LoadingButton loading={loading}
        disabled={!available || loading}
        onClick={addToCart}
        color={justAddedToCart ? 'success' : 'primary'}
        startIcon={justAddedToCart ? <Check /> : <Add />}
        sx={{ width: simple ? '100%' : 'auto' }}
        variant={simple ? 'contained' : 'contained'}>{available ? "Add to cart" : "Out of Stock"}</LoadingButton>

      {addedToCart && <Button variant='contained' color='success' component={Link} to='/cart'
        sx={{ width: simple ? '100%' : 'auto' }} startIcon={<Check />}>Checkout</Button>}

      {available && withBuyNow && <BuyNowButton as={LoadingButton} loading={loading}
        disabled={loading}
        variantId={variantId}
        variant='contained'>{"Buy now"}</BuyNowButton>}

      {available && withShopPay && <ShopPayButton
        width='150px'
        variantIds={[variantId]} />
      }
    </Stack>

  )
}

type CartButtonProps = {
  quantity: number;
  onClick?: () => void;
}

export function CartButton({ quantity, onClick }: CartButtonProps) {
  const theme = useTheme();

  return (

    <IconButton style={{ color: theme.palette.common.white }} size="large" component={Link} aria-label={`Shopping cart has ${quantity} items`} onClick={onClick} to="/cart">
      <Badge badgeContent={quantity > 0 ? quantity : undefined} color="error">
        <ShoppingBagOutlined fontSize="inherit" />
      </Badge>
    </IconButton>
  )
}

type CartLineItemProps = {
  isCartLoading: boolean;
}

export function CartLineItem({ isCartLoading }: CartLineItemProps) {
  const theme = useTheme();
  const styles = useCartStyling(theme)
  const item = useCartLine();
  const { linesUpdate, linesRemove } = useCart();

  const handleRemove = () => {
    linesRemove([item.id])
  }

  const handleQuantityChange = (value: number) => {
    if (Number(value) < 0) {
      return
    }

    const numberVal = Number(value);

    if (numberVal >= 1) {

      linesUpdate([
        {
          id: item.id,
          merchandiseId: item.merchandise.id,
          quantity: numberVal
        }
      ])
    }
    else if (numberVal === 0) {
      linesRemove([item.id])
    }
  }

  const allLoading = false;

  const productRoute = `/products/${item.merchandise.product.handle}`

  // console.log('heree image', item.merchandise.image)

  return (
    <TableRow
      key={item.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell sx={styles.productImageCell}>
        {/* <ShopifyImage data={item.merchandise.image} height="100px" /> */}
        <img src={item.merchandise.image.url} style={{ height: "150px" }} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Stack gap={1}>
          <Stack gap={0.2}>
            {/* <Typography variant='body1' component='div'><Button variant='text' sx={{ p: 0 }} component={Link} to={productRoute}>{antiSlugify(item.merchandise.product.productType)}</Button></Typography> */}
            <Typography variant='body1' component='div'><Button variant='text' sx={{ p: 0 }} component={Link} to={productRoute}>{item.merchandise.product.title}</Button></Typography>
          </Stack>
          <Stack gap={0.2}>
            {item.merchandise.selectedOptions?.map(i =>
              <Typography key={i.name} variant='subtitle2' component='div'>{i.name} - {i.value}</Typography>
            )}
          </Stack>
        </Stack>
      </TableCell>
      <TableCell align="right" sx={styles.lineItemSubtotalCell}><MoneyDisplay money={item.cost.amountPerQuantity} nonDiscounted={item.cost.compareAtAmountPerQuantity} /></TableCell>
      <TableCell align="right">
        <NumericInput
          disabled={allLoading}
          value={item.quantity}
          includeZero={true}
          aria-label="Quantity"
          variant="outlined"
          size="small"
          onChange={(v) => handleQuantityChange(v)}
        />
      </TableCell>
      <TableCell align="right"><MoneyDisplay money={item.cost.totalAmount} nonDiscounted={item.cost.compareAtAmountPerQuantity ? { ...item.cost.compareAtAmountPerQuantity, currencyCode: item.cost.compareAtAmountPerQuantity.currencyCode, amount: (parseFloat(item.cost.compareAtAmountPerQuantity.amount) * item.quantity).toFixed(2) } : undefined} /></TableCell>
      {/* <TableCell align="right"><MoneyDisplay money={subtotal} /></TableCell> */}
      <TableCell align="right" sx={styles.cartDesktopActionsCell}>
        <Tooltip title="Remove">
          <IconButton onClick={handleRemove} disabled={allLoading} aria-label={`Remove ${item.merchandise.product.title} from cart`}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

type MoneyDisplayProps = {
  money: Queries.ShopifyMoneyV2 | MoneyV2
  nonDiscounted?: Queries.ShopifyMoneyV2 | MoneyV2
  direction?: 'row' | 'column'
  showSavePill?: boolean
}

export const MoneyDisplay = ({ money, nonDiscounted, direction = 'column', showSavePill }: MoneyDisplayProps) => {
  const parsed = useMoney(money as unknown as MoneyV2);
  const parsedNonDiscounted = nonDiscounted ? useMoney(nonDiscounted as unknown as MoneyV2) : null;
  const theme = useTheme()

  const discountPercent = nonDiscounted ? Math.abs(100 - Math.abs(Math.round((1 - (parseFloat(parsedNonDiscounted.amount) / parseFloat(parsed.amount)) * 100)))) : 0;

  return (
    <Stack direction={direction} gap={direction === 'column' ? 1 : 3}>
      <Box>{(`${parsed.currencyNarrowSymbol}${parsed.amount} ${parsed.currencyCode}`).trim()}
      </Box>
      {parsedNonDiscounted && <Box>
        <Typography style={{ textDecoration: 'line-through', color: theme.palette.text.secondary }}>{(`${parsedNonDiscounted.currencyNarrowSymbol}${parsedNonDiscounted.amount} ${parsedNonDiscounted.currencyCode}`).trim()}</Typography>
      </Box>
      }
      {parsedNonDiscounted && showSavePill && discountPercent && <Chip size='small' color='success' label={`Sale - Save ${discountPercent}%`} />
      }
    </Stack>
  )
}