import { graphql, useStaticQuery } from 'gatsby';

interface SiteMetadata {
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      siteUrl: string
    }
  }
}

export const useSeoMetadata = () => {
  const data: SiteMetadata = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `)

  return {
    site: {
      ...data.site.siteMetadata,
      image: `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
    }
  }
}