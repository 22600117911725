import { createTheme, responsiveFontSizes } from '@mui/material';

import overrides from './overrides';
import palette from './palette';
import shape from './shape';
import typography from './typography';

// defaults - https://mui.com/material-ui/customization/breakpoints/
export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
}

let theme = createTheme({
  palette,
  typography,
  components: {
    ...overrides,
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: 'none',
          background: 'none',
        },

      }
    },
    MuiAccordionSummary: {
      styleOverrides: {

      }
    }
  },
  shape,
  breakpoints: {
    values: breakpointValues,
  },
})
theme = responsiveFontSizes(theme);

export default theme