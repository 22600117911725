import React from 'react';

import { Box, Divider, Stack, Theme, Typography, useTheme } from '@mui/material';

type Props = {
    banners: { text: string }[]
}

export const bannerHeight = 40;

const useStyles = (theme: Theme) => ({
    container: {
        // position: 'absolute',
        // zIndex: 99,
        // top: 0,
        // left: 0,
        fontWeight: theme.typography.fontWeightBold,
        // color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.dark,
        width: '100%'
    },
    content: {
        textAlign: 'center',
        fontSize: {
            xs: '0.8rem',
            sm: 'initial',
            md: 'initial'
        }
    },
    divider: {
        borderColor: theme.palette.text.primary,
        height: '20px'
    }
})

export const SiteBanner = ({ banners }: Props) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    if (!banners || banners.length === 0) return <></>;

    return (
        <Box sx={styles.container}>
            <Stack direction="row" sx={{ height: bannerHeight + 'px' }}
                divider={<Divider orientation="vertical" sx={styles.divider} />}
                spacing={2} alignItems='center' justifyContent='center'>
                {banners.map((banner, index) => (
                    <Box sx={styles.content} key={index}>
                        <Typography variant="h5" sx={{ fontSize: { xs: banners.length > 1 ? '0.8rem' : 'initial', sm: 'initial' } }}>{banner.text}</Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    )
}