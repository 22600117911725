export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => {
      return {
        ...(ownerState.size !== 'small' && {
          borderRadius: '0.75rem',
        }),
        ...(ownerState.size == 'small' && {
          borderRadius: '0.5rem',
        }),
      }
    },
  }
}
