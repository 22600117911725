import { last } from 'lodash-es';

import {
    CommerceActivityTrackingEventProductMetadata, ECommerceContentType
} from '@seeka-labs/converge';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import { AnalyticsPageType, ShopifyAnalyticsProduct, useCart, useShop } from '@shopify/hydrogen-react';

import { ShopifyPageViewPayloadMinimal } from '../models/tracking';
import { useShopifyAnalytics } from '../shopify/analytics';
import { antiSlugify } from '../utils';

export const useAnalytics = () => {
    const shopifyAnalytics = useShopifyAnalytics();
    const converge = useSeekaConverge();
    const isSsr = typeof window === 'undefined';
    const klav = !isSsr ? (window as any).klaviyo : undefined;
    const cart = useCart();

    const trackPageView = async (pageType: string, data?: ShopifyPageViewPayloadMinimal, variantId?: string) => {
        if (isSsr) return;

        shopifyAnalytics.trackPageView(pageType, {
            ...data,
            products: data?.products.map(p => shopifyProductFragementToShopifyAnalyticsProduct(p, variantId))
        })

        if (data?.products && data?.products.length > 0) {
            if (pageType === AnalyticsPageType.collection || pageType === AnalyticsPageType.listCollections) {
                const shopifyProducts = data?.products.map(p => shopifyProductToSeekaProduct(p, variantId));
                const contentItem = {
                    contentType: ECommerceContentType.Collection,
                    contentName: data.collectionHandle,
                    // currencyCode: variants && variants.length > 0 ? variants[0].price?.currencyCode : '',
                    value: undefined,
                    identifiers: shopifyProducts.map(p => p.variantIdentifier || p.productIdentifier),
                    items: data?.products.map(p => shopifyProductToSeekaProduct(p, variantId))
                };
                converge.track.viewContentItem(contentItem)
            }
            else {
                data.products.forEach(product => {
                    // Seeka
                    converge.track.viewProduct(shopifyProductToSeekaProduct(product, variantId))

                    const klavProduct = shopifyProductToKlaviyoProduct(product, 1, variantId);

                    if (klav) {
                        // Klaviyo
                        // https://developers.klaviyo.com/en/v1-2/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#viewed-product
                        klav.push(["track", "Viewed Product", {
                            "ProductName": klavProduct.ProductName,
                            "ProductID": klavProduct.ProductID,
                            "SKU": klavProduct.SKU,
                            "Categories": klavProduct.ProductCategories,
                            "ImageURL": klavProduct.ImageURL,
                            "URL": klavProduct.ProductURL,
                            "Brand": klavProduct.brandName,
                            "Price": klavProduct.unitPrice,
                            // "CompareAtPrice": 14.99
                        }]);

                        klav.push(["trackViewedItem", {
                            "Title": klavProduct.ProductName,
                            "ItemId": klavProduct.ProductID,
                            "Categories": klavProduct.ProductCategories,
                            "ImageUrl": klavProduct.ImageURL,
                            "Url": klavProduct.ProductURL,
                            "Metadata": {
                                "Brand": product.brand,
                                "Price": klavProduct.ItemPrice,
                                // "CompareAtPrice": product.
                            }
                        }]);
                    }
                })
            }
        }
        // converge.track.viewPage();
    }

    const trackAddToCart = async (pageType: string, products: Queries.ShopifyProductCardFragment[], variantId?: string, quantity?: number) => {
        if (isSsr) return;

        shopifyAnalytics.trackAddToCart(pageType, [
            ...products.map(p => {
                return {
                    ...shopifyProductFragementToShopifyAnalyticsProduct(p, variantId),
                    quantity: quantity || 1
                }
            })
        ])

        products.forEach(product => {
            converge.track.addToCart({
                ...shopifyProductToSeekaProduct(product, variantId),
                quantity: quantity || 1
            })
        })

        // Klaviyo
        if (klav) {
            // https://developers.klaviyo.com/en/v1-2/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#viewed-product
            const productsMapped = products.map(e => shopifyProductToKlaviyoProduct(e, quantity, variantId));
            if (productsMapped.length > 0) {
                const klavProduct = productsMapped[0];

                klav.push(["track", 'Added to Cart', {
                    "$value": klavProduct.RowTotal,
                    "AddedItemProductName": klavProduct.ProductName,
                    "AddedItemProductID": klavProduct.ProductID,
                    "AddedItemSKU": klavProduct.SKU,
                    "AddedItemCategories": klavProduct.ProductCategories,
                    "AddedItemImageURL": klavProduct.ImageURL,
                    "AddedItemURL": klavProduct.ProductURL,
                    "AddedItemPrice": klavProduct.ItemPrice,
                    "AddedItemQuantity": quantity,
                    "ItemNames": products.map(e => e.name),
                    "CheckoutURL": cart.checkoutUrl,
                    "Items": productsMapped
                }]);
            }
        }
    }

    return {
        trackPageView,
        trackAddToCart
    }
}

const shopifyProductFragementToShopifyAnalyticsProduct = (product: Queries.ShopifyProductCardFragment, variantId?: string): ShopifyAnalyticsProduct => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;
    return {
        ...product,
        price: (variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount).toFixed(2),
    }
}

const getShopifyVariant = (product: Queries.ShopifyProductCardFragment, variantId: string) => {
    return product.variants.find(v => v.shopifyId === variantId);
}

const removeShopifyIdPath = (shopifyId: string) => {
    if (!shopifyId) return null;
    return last(shopifyId.split('/'));
}

const shopifyProductToSeekaProduct = (product: Queries.ShopifyProductCardFragment, variantId?: string): CommerceActivityTrackingEventProductMetadata => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;

    return {
        productIdentifier: removeShopifyIdPath(product.storefrontId),
        variantIdentifier: variant ? removeShopifyIdPath(variant.storefrontId) : null,
        categoryName: antiSlugify(product.productType),
        brandName: product.brand,
        productName: product.name,
        variantName: variant ? variant.title : null,
        sku: variant ? variant.sku : null,
        unitPrice: variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount,
        currencyCode: product.priceRangeV2.maxVariantPrice.currencyCode,
    }
}

const shopifyProductToKlaviyoProduct = (product: Queries.ShopifyProductCardFragment, quantity: number, variantId?: string): any => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;

    const [firstImage] = product.media;
    const imageUrl = (firstImage as any).image.gatsbyImageData.images.fallback.src;

    const price = variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount;
    const categoryName = antiSlugify(product.productType);
    // TODO: get quan
    const klavProduct = {
        "ProductID": removeShopifyIdPath(product.storefrontId),
        // "ProductID": variant ? removeShopifyIdPath(variant.storefrontId) : removeShopifyIdPath(product.storefrontId),
        "SKU": variant ? variant.sku : null,
        "ProductName": product.name,
        "Quantity": quantity,
        "ItemPrice": price,
        "RowTotal": price * quantity,
        "ProductURL": `${window.location.protocol}//${window.location.hostname}${window.location.protocol !== 'https:' ? (':' + window.location.port) : ''}${product.slug}`,
        "ImageURL": imageUrl,
        "ProductCategories": categoryName ? [categoryName] : [],
    }

    // console.log('heree', klavProduct)

    return klavProduct
}