import { useEffect, useMemo } from 'react';

import {
    AnalyticsEventName, getClientBrowserParameters, sendShopifyAnalytics, ShopifyAddToCartPayload,
    ShopifyAnalyticsProduct, ShopifyPageViewPayload, useCart, useShop, useShopifyCookies
} from '@shopify/hydrogen-react';

import { ShopifyPageViewPayloadMinimal } from '../../models/tracking';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import { isTrackingEnabled } from '../../utils';

export const useShopifyAnalytics = () => {
    const shop = useShop();
    const cart = useCart();
    const converge = useSeekaConverge();
    const isSsr = typeof window === 'undefined';
    const isTrackingAllowed = isTrackingEnabled()

    // console.log('heree shopify cart', cart)

    // TODO: integrate with consent popup
    const hasUserConsent = true;

    useEffect(() => {
        const cartGid = cart?.id;
        if (cartGid && converge) {
            const cartParts = cartGid.split('?')[0]?.split('/');
            if (cartParts.length > 1) {
                const cartToken = cartParts[cartParts.length - 1];
                converge.identity.mergeProfile({
                    shopify: {
                        cartToken: [cartToken]
                    }
                })
            }

        }

    }, [cart?.id])

    const currencyCode = useMemo(() => {
        return cart?.cost?.totalAmount?.currencyCode;
    }, [shop, cart])

    const trackPageView = async (pageType: string, data?: Omit<ShopifyPageViewPayloadMinimal, 'products'> & { products: ShopifyAnalyticsProduct[] }) => {
        if (!isTrackingAllowed) return; // Helps lighthouse score, sendShopifyAnalytics will fail

        // Send analytics payload to Shopify
        await sendShopifyAnalytics({
            eventName: AnalyticsEventName.PAGE_VIEW,
            payload: {
                ...getClientBrowserParameters(),
                ...data,
                // acceptedLanguage: `${shop.languageIsoCode}-${shop.countryIsoCode}` as LanguageCode, // TODO
                hasUserConsent: hasUserConsent,
                currency: currencyCode,
                shopId: `gid://shopify/Shop/${process.env.GATSBY_SHOPIFY_SHOP_ID}`,
                pageType: pageType,
                // storefrontId: shop.storefrontId
            } as ShopifyPageViewPayload,
        });
    }

    const trackAddToCart = async (pageType: string, products: ShopifyAnalyticsProduct[]) => {
        if (!isTrackingAllowed) return; // Helps lighthouse score, sendShopifyAnalytics will fail

        // Send analytics payload to Shopify
        await sendShopifyAnalytics({
            eventName: AnalyticsEventName.ADD_TO_CART,
            payload: {
                ...getClientBrowserParameters(),
                // ...data,
                // acceptedLanguage: `${shop.languageIsoCode}-${shop.countryIsoCode}` as LanguageCode, // TODO
                hasUserConsent: hasUserConsent,
                shopId: `gid://shopify/Shop/${process.env.GATSBY_SHOPIFY_SHOP_ID}`,
                pageType: pageType,
                cartId: cart?.id,
                currency: currencyCode,
                products: products,
                // storefrontId: shop.storefrontId
            } as ShopifyAddToCartPayload,
        });
    }

    return {
        trackPageView,
        trackAddToCart
    }
}