export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => {
      return {
        textTransform: 'inherit' as any,
        // padding: '0.75rem 1.5rem',
        lineHeight: 'normal',
        // fontWeight: 500,
        // fontSize: '0.875rem',
        whiteSpace: 'nowrap' as any,
        ...(ownerState.size !== 'small' && {
          padding: '0.75rem 1.5rem',
          borderRadius: '0.75rem',
        }),
        ...(ownerState.size == 'small' && {
          //padding: '0.75rem 1.5rem',
          borderRadius: '0.5rem',
          padding: '0.3rem 0.7rem',
        }),
        ...(ownerState.variant == 'outlined' && {
          borderWidth: '2px'
        }),
        ':hover': {
          ...(ownerState.variant == 'outlined' && {
            borderWidth: '2px'
          }),
        },
      }
    },
  }
}
