import MuiAlert from './MuiAlert';
import MuiButton from './MuiButton';
import MuiButtonGroup from './MuiButtonGroup';
import MuiInput from './MuiInput';

const overrides = {
  MuiButton,
  MuiButtonGroup,
  MuiInput,
  MuiAlert,
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: "white",
      },
      html: {
        background: "#F3F8FF",
        minHeight: '100vh'
      }
    }
  }
}

export default overrides
