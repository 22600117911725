import * as React from 'react';

import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		viewBox="0 0 60 40" xmlSpace="preserve">
		<title>discover</title>
		<desc>Created with Sketch.</desc>
		<rect fill="#F3F6F9" fillRule='evenodd' clipRule="evenodd" width="60" height="40" />
		<g>
			<path fill="#F48628" d="M31.4,16c-2.2,0-4.1,1.8-4.1,3.9c0,2.3,1.7,4,4.1,4c2.3,0,4.1-1.7,4.1-4C35.4,17.8,33.6,16,31.4,16z" />
			<path fill="#001722" d="M8.3,16.2H6.1v7.6h2.2c1.2,0,2-0.3,2.7-0.9c0.9-0.7,1.4-1.8,1.4-2.9C12.4,17.7,10.7,16.2,8.3,16.2z M10,21.9
		c-0.5,0.4-1.1,0.6-2,0.6H7.6v-5H8c1,0,1.5,0.2,2,0.6c0.5,0.5,0.8,1.2,0.8,1.9C10.8,20.7,10.5,21.4,10,21.9z"/>
			<rect x="13" y="16.2" fill="#001722" width="1.5" height="7.6" />
			<path fill="#001722" d="M18.2,19.1c-0.9-0.3-1.2-0.5-1.2-1c0-0.5,0.5-0.8,1.1-0.8c0.4,0,0.8,0.2,1.2,0.6l0.8-1
		c-0.6-0.6-1.4-0.8-2.2-0.8c-1.3,0-2.4,0.9-2.4,2.2c0,1.1,0.5,1.6,1.9,2.1c0.6,0.2,0.9,0.3,1,0.4c0.3,0.2,0.4,0.5,0.4,0.8
		c0,0.6-0.5,1.1-1.2,1.1c-0.7,0-1.3-0.4-1.6-1l-1,0.9c0.7,1,1.5,1.5,2.6,1.5c1.5,0,2.6-1,2.6-2.5C20.4,20.3,19.9,19.7,18.2,19.1z"/>
			<path fill="#001722" d="M20.8,20c0,2.2,1.8,4,4,4c0.6,0,1.2-0.1,1.9-0.4v-1.8c-0.6,0.6-1.1,0.8-1.8,0.8c-1.5,0-2.6-1.1-2.6-2.6
		c0-1.5,1.1-2.6,2.5-2.6c0.7,0,1.2,0.3,1.9,0.9v-1.8c-0.7-0.3-1.2-0.5-1.8-0.5C22.6,16,20.8,17.8,20.8,20z"/>
			<polygon fill="#001722" points="38.8,21.3 36.8,16.2 35.1,16.2 38.4,24 39.2,24 42.5,16.2 40.9,16.2 	" />
			<polygon fill="#001722" points="43.1,23.8 47.4,23.8 47.4,22.5 44.6,22.5 44.6,20.5 47.3,20.5 47.3,19.2 44.6,19.2 44.6,17.5 
		47.4,17.5 47.4,16.2 43.1,16.2 	"/>
			<path fill="#001722" d="M53.3,18.4c0-1.4-1-2.3-2.7-2.3h-2.2v7.6h1.5v-3.1H50l2.1,3.1h1.8l-2.4-3.2C52.6,20.4,53.3,19.6,53.3,18.4z
		 M50.3,19.7h-0.4v-2.3h0.5c0.9,0,1.4,0.4,1.4,1.1C51.7,19.3,51.2,19.7,50.3,19.7z"/>
		</g>
	</svg>
	, '')
