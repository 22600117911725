const black = '#1E1F1F'
const darkBlack = '#111111'
const white = '#FFFFFF'

interface ThemeGradient {
  start: string
  end: string
  contrastText: string
}

interface ThemeGradients {
  dark: ThemeGradient
  light: ThemeGradient
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    gradients: ThemeGradients
  }
}

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#425458',
  },
  primary: {
    contrastText: darkBlack,
    main: '#2EC3E9',
    light: '#cbf5ff',
    dark: '#28a7c7',
  },
  secondary: {
    contrastText: darkBlack,
    main: '#EE4E9B',
    light: '#dfbfce',
    dark: '#c0407e',
  },
  success: {
    contrastText: white,
    main: '#1fb18e',
    light: '#E1F7DE',
    dark: '#009D62',
  },
  info: {
    contrastText: white,
    main: '#92ceee',
    light: '#E0EFF6',
    dark: '#377292',
  },
  warning: {
    contrastText: white,
    main: '#ff8400',
    light: '#e4d7b1',
    dark: '#E9B100',
  },
  error: {
    contrastText: white,
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
  },
  text: {
    primary: darkBlack,
    secondary: '#666',
    disabled: '#A6B1BB',
  },
  gradients: {
    dark: {
      start: black,
      end: '#272B64',
      contrastText: white,
    },
    light: {
      start: "#E2F1FF",
      end: "#F9F0FF",
      contrastText: black,
    }
  },
  background: {
    default: '#F3F8FF',
    dark: '#f3f3f3',
    paper: '#f9f9f9',
  }
}
