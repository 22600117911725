import { graphql, Link, useStaticQuery } from 'gatsby';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';

import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import styled from '@emotion/styled';
import { Close, Menu } from '@mui/icons-material';
import {
  AppBar, Box, Button as MuiButton, Container, IconButton, Stack, Theme, Toolbar, useTheme
} from '@mui/material';
import { useCart } from '@shopify/hydrogen-react';

import { useAutomaticDiscountList } from '../shared/hooks/useAutomaticDiscountList';
import { notPrintable } from '../shared/styles/notPrintable';
import { CartButton } from './cart';
import NavItemGroup, { NavItemGroupNavItem } from './nav-item-group';
import { bannerHeight, SiteBanner } from './site-banner';
import { Button, ContentfulAssetImage, NavLink } from './ui';
import { ConvergeSdk, PersonIdentifiers, TrackingActivityNames } from '@seeka-labs/converge';

export const mobileNavLinkStyle = (muiTheme: Theme) => ({
  display: "block",
  color: "white",
  zIndex: 1,
  fontSize: muiTheme.typography.h3.fontSize,
  paddingTop: muiTheme.spacing(1),
  paddingBottom: muiTheme.spacing(1),
  paddingLeft: muiTheme.spacing(4),
  paddingRight: muiTheme.spacing(4),
  marginBottom: muiTheme.spacing(1),
})

export const mobileAppFloatingMenuStyle = (muiTheme: Theme) => ({
  color: "white",
  zIndex: 999,
  fontSize: muiTheme.typography.h3.fontSize,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  pt: 10,
  pl: 1,
  pr: 1,
  position: "absolute",
  background: `linear-gradient(180deg, ${muiTheme.palette.gradients.dark.end} -50%, ${muiTheme.palette.gradients.dark.start} 100%)`,
})

export const desktopAppBarStyle = () => ({
  background: "transparent",
  pt: 2,
  display: {
    xs: "none",
    sm: "none",
    md: "none",
    lg: "inherit",
    xl: "inherit",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const mobileAppBarStyle = () => ({
  background: "transparent",
  pt: 1,
  pr: 1,
  pl: 0,
  display: {
    xs: "inherit",
    sm: "inherit",
    md: "inherit",
    lg: "none",
    xl: "none",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export type NavItem = {
  contentful_id: string
  id: string
  navItemType: "Link"
  href: string
  text: string
}

export type NavItemGroup = {
  contentful_id: string
  id: string
  navItemType: "Group"
  name: string
  navItems: NavItemGroupNavItem[]
}

export const baseLogoHeight = 48
export const baseLogoWidth = 230

export default function Header({ location, isSiteFramed }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { status, totalQuantity, lines } = useCart();
  const cartQuantity = totalQuantity || 0;

  const convergeStatus = typeof window !== 'undefined' ? ((window as any).Converge as ConvergeSdk | undefined)?.status : undefined;

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        const converge = ((window as any).Converge as ConvergeSdk | undefined);
        if (converge && converge.handlers && converge.status && converge.status.isInitialised) {
          converge.handlers.getCart = async () => {
            return {
              products: lines.map(line => {
                // console.log('heree line', line)
                return {
                  productIdentifier: line.merchandise.product.id.split('/').splice(-1)[0],
                  productName: line.merchandise.product.title,
                  categoryName: line.merchandise.product.productType,
                  quantity: line.quantity,
                  currencyCode: line.cost.amountPerQuantity.currencyCode,
                  sku: line.merchandise.sku,
                  unitPrice: parseFloat(line.cost.amountPerQuantity.amount),
                }
              }),
            }
          }
        }

      }
    }, 3000)
  }, [convergeStatus])

  useEffect(() => {
    // console.log('heree status', status)
    if (status === 'updating' && location.pathname !== '/cart' && location.pathname !== '/cart/') {
      setTimeout(() => {
        enqueueSnackbar('Updated cart', { variant: 'success', action: <MuiButton component={Link} to="/cart" onClick={() => closeSnackbar()} size='small' sx={{ color: 'white', borderColor: 'white!important', fontWeight: 'normal' }} variant="outlined" disableElevation>View cart</MuiButton> })
      }, 1000)
    }
  }, [status])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("converge.activity.tracked", (ev) => {
        const activityName: TrackingActivityNames = (ev as any).detail?.activityName;
        const activityNameCustom: string | undefined | null = (ev as any).detail?.props?.activityNameCustom;
        const klav = (window as any).klaviyo;

        if (!activityName || !klav || activityName === TrackingActivityNames.ViewPage) return;

        if (activityName === TrackingActivityNames.Custom && activityNameCustom) {
          klav.track(activityNameCustom);
        }
        else if (activityName === TrackingActivityNames.ViewProduct || activityName === TrackingActivityNames.AddToCart) {
          // no-op
        }
        else if (activityName === TrackingActivityNames.ViewContentItem) {
          // no-op
        }
        else {
          klav.track(activityName);
        }
      });

      window.addEventListener("converge.identity.changed", (ev) => {
        const seekaIdentity = (ev as any).detail?.identifiers as PersonIdentifiers | undefined | null;
        const seekaPId = seekaIdentity?.seekaPId;
        const klav = (window as any).klaviyo;

        const converge = ((window as any).Converge as ConvergeSdk | undefined);
        if (klav && seekaIdentity && seekaPId && converge) {
          const klavIdentity: any = {
            external_id: seekaPId
          };
          if (seekaIdentity.email && seekaIdentity.email.length > 0) {
            klavIdentity['email'] = seekaIdentity.email[0];
          }
          if (seekaIdentity.phone && seekaIdentity.phone.length > 0) {
            klavIdentity['phone_number'] = seekaIdentity.phone[0];
          }
          if (seekaIdentity.firstName && seekaIdentity.firstName.length > 0) {
            klavIdentity['first_name'] = seekaIdentity.firstName[0];
          }
          if (seekaIdentity.lastName && seekaIdentity.lastName.length > 0) {
            klavIdentity['last_name'] = seekaIdentity.lastName[0];
          }
          klav.identify(klavIdentity);
        }

        // const clar = (window as any).clarit
        // if (clar && seekaPId) {
        //   clar("set", "seekaProfileId", seekaPId);
        // }
      });

      // Klaviyo newsletter tracking
      window.addEventListener("klaviyoForms", (e) => {
        const evData = (e as any).detail;
        if (evData && evData.type == 'submit') {
          if (evData.formId === "S8A58k") {
            // newsletter form          
            ((window as any).Converge as ConvergeSdk | undefined)?.track.newsletterSignup();
          }
          if (evData.metaData && (evData.metaData['$email'] || evData.metaData['$phone_number'])) {
            ((window as any).Converge as ConvergeSdk | undefined)?.identity.mergeProfile({
              email: evData.metaData['$email'] ? [evData.metaData['$email']] : [],
              phone: evData.metaData['$phone_number'] ? [evData.metaData['$phone_number']] : [],
            })
          }
        }
      });
    }
  }, [])

  const headerDataQuery: Queries.HeaderComponentQuery = useStaticQuery(graphql`
    query HeaderComponent {
      layoutHeader: contentfulLayoutHeader {
        id
        contentful_id
        __typename
        logo {
          id
          title
          url
          gatsbyImageData(placeholder: NONE, width: 230, quality: 100)
          contentful_id
          __typename
        }
        navItems {
          id
          contentful_id
          __typename
          ... on ContentfulNavItem {
            href
            text
          }
        }
        cta {
          id
          href
          text
          contentful_id
          __typename
        }
      }
    }
  `)

  const { activeDiscounts } = useAutomaticDiscountList();

  const headerData = useContentfulLiveUpdates({
    ...headerDataQuery.layoutHeader,
    sys: { id: headerDataQuery.layoutHeader.contentful_id },
  });

  const NotPrintable = useMemo(() => styled('div')(notPrintable), []);

  const banners = activeDiscounts.map((node) => ({ text: node.title }))

  const hasSiteBanners = useMemo(() => banners.length > 0, [banners])
  const headerProps = useMemo(() => {
    return { ...headerDataQuery, layoutHeader: headerData }
  }, [])

  return (
    isSiteFramed ? <></> :
      <>
        <NotPrintable>
          <SiteBanner banners={banners} />
          <MobileHeader cartQuantity={cartQuantity} hasSiteBanners={hasSiteBanners} layoutHeader={headerProps.layoutHeader} />
          <DesktopHeader cartQuantity={cartQuantity} hasSiteBanners={hasSiteBanners} layoutHeader={headerProps.layoutHeader} />
        </NotPrintable>
      </>
  )
}

type HeaderComponentProps = Queries.HeaderComponentQuery & {
  hasSiteBanners: boolean
  cartQuantity: number
}

const DesktopHeader = ({ cartQuantity, hasSiteBanners, layoutHeader }: HeaderComponentProps) => {
  const { navItems, cta, logo } = layoutHeader
  const theme = useTheme()

  const appBarStyle = desktopAppBarStyle()
  const inspectorProps = useContentfulInspectorMode();

  return (
    <AppBar position="absolute" elevation={0} sx={{ ...appBarStyle, marginTop: hasSiteBanners ? bannerHeight + 'px' : undefined }}>
      <Toolbar>
        <Container>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <NavLink to="/">
              <Box sx={{ display: "none" }}>Home</Box>
              <ContentfulAssetImage
                asset={logo}
                loading="eager"
                imgStyle={{
                  width: baseLogoWidth + "px",
                  height: baseLogoHeight + "px",
                }}
                contentfulPreviewProps={inspectorProps({
                  entryId: layoutHeader.contentful_id,
                  fieldId: 'logo',
                })}
              />
            </NavLink>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              gap={{ md: 2, lg: 4 }}
            >
              <nav
                {...inspectorProps({
                  entryId: layoutHeader.contentful_id,
                  fieldId: 'navItems',
                })}>
                <Stack
                  gap={{ md: 1, lg: 2 }}
                  direction={"row"}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {navItems &&
                    navItems.map((navItem) => (
                      <div key={navItem.id}>
                        <NavLink
                          to={navItem.href}
                          sx={{
                            color: theme.palette.gradients.dark.contrastText,
                          }}
                          contentfulPreviewProps={inspectorProps({
                            entryId: navItem.contentful_id,
                            fieldId: 'text',
                          })}
                        >
                          {navItem.text}
                        </NavLink>
                      </div>
                    ))}
                </Stack>
              </nav>

              {/* <Link to="/search" className={searchButton}>
          <SearchIcon />
        </Link> */}
              {cta && (
                <div {...inspectorProps({
                  entryId: cta.id,
                  fieldId: 'text',
                })}>
                  <Button
                    to={cta.href}
                    variant="contained"
                    disableElevation
                    sx={
                      {
                        borderColor: "white",
                      }
                    }
                  >
                    {cta.text}
                  </Button>
                </div>
              )}
              <CartButton quantity={cartQuantity} />
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

const MobileHeader = ({ cartQuantity, hasSiteBanners, layoutHeader }: HeaderComponentProps) => {
  const { navItems, cta, logo } = layoutHeader
  const inspectorProps = useContentfulInspectorMode();

  const theme = useTheme()

  const appBarStyle = mobileAppBarStyle()
  const openMenuStyle = mobileAppFloatingMenuStyle(theme)
  const mobileLinkStyling = mobileNavLinkStyle(theme)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.overflow = ""
    }
  }, [isOpen])

  return (
    <>
      <AppBar position="absolute" elevation={0} sx={{ ...appBarStyle, marginTop: hasSiteBanners && !isOpen ? bannerHeight + 'px' : undefined }}>
        <Toolbar sx={{ p: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <NavLink to="/" onClick={() => setOpen(false)}>
              <Box sx={{ display: "none" }}>Home</Box>
              <Box
                sx={{
                  maxHeight: {
                    xs: baseLogoHeight * 0.75 + "px",
                    sm: baseLogoHeight * 0.85 + "px",
                    md: baseLogoHeight + "px",
                  },
                  // width: {
                  //   xs: baseLogoWidth * 0.75 + "px",
                  //   sm: baseLogoWidth * 0.85 + "px",
                  //   md: baseLogoWidth + "px",
                  // },
                }}
              >
                <ContentfulAssetImage
                  asset={logo}
                  loading="eager"
                  objectFit="contain"
                  contentfulPreviewProps={inspectorProps({
                    entryId: layoutHeader.contentful_id,
                    fieldId: 'logo',
                  })}
                />
              </Box>
            </NavLink>

            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              justifyContent={"flex-end"}
            >
              {!isOpen && cta && (
                <Button
                  to={cta.href}
                  variant={isOpen ? "outlined" : "contained"}
                  sx={{
                    color: "white",
                    borderColor: "white",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    p: { xs: "7px 12px", sm: "10px 16px" },
                    // marginRight: isOpen ? "18px" : null,
                  }}
                  {...inspectorProps({
                    entryId: layoutHeader.contentful_id,
                    fieldId: 'cta',
                  })}
                >
                  {cta.text}
                </Button>
              )}
              {/* <Link to="/search" className={searchButton}>
          <SearchIcon />
        </Link> */}
              <CartButton quantity={cartQuantity} onClick={() => setOpen(false)} />
              <div>
                <IconButton
                  title="Toggle menu"
                  onClick={() => setOpen(!isOpen)}
                >
                  {isOpen ? (
                    <Close sx={{ color: "white" }} />
                  ) : (
                    <Menu sx={{ color: "white" }} />
                  )}
                </IconButton>
              </div>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {isOpen && (
        <Box sx={openMenuStyle}>
          <nav>
            <Stack direction={'column'} sx={{ width: '100%' }} alignItems={'stretch'}>
              {navItems?.map((navItem) =>
                <NavLink
                  key={navItem.id}
                  to={navItem.href}
                  onClick={() => setOpen(false)}
                  style={mobileLinkStyling}
                  contentfulPreviewProps={inspectorProps({
                    entryId: navItem.contentful_id,
                    fieldId: 'text',
                  })}
                >
                  {navItem.text}
                </NavLink>
              )}
              {cta && (
                <Box sx={{ ml: 4, mr: 4, mt: 3 }}>
                  <NavLink
                    to={cta.href}
                    variant={"contained"}
                    onClick={() => setOpen(false)}
                    // style={mobileLinkStyling}
                    sx={{
                      color: "white",
                      borderColor: "white",
                      fontSize: "1rem",
                      display: 'block',
                      textAlign: 'center'
                      // p: "10px 16px",
                      // mt: "20px",
                      // ml: "20px",
                      // mr: "25px",
                      // marginRight: isOpen ? "18px" : null,
                    }}
                    contentfulPreviewProps={inspectorProps({
                      entryId: layoutHeader.contentful_id,
                      fieldId: 'cta',
                    })}
                  >
                    {cta.text}
                  </NavLink>
                </Box>
              )}
            </Stack>
          </nav>
        </Box>
      )}
    </>
  )
}
