import * as React from 'react';

import { MenuItem, Select, SelectProps } from '@mui/material';

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

type Props = Exclude<SelectProps<number>, 'onChange'> & {
  onChange: (value: number) => void,
  includeZero?: boolean
}

export function NumericInput({
  onChange,
  includeZero,
  ...other
}: Props) {
  return (
    <Select
      {...other}
      onChange={(e) => onChange(e.target.value as number)}
    >
      {includeZero && <MenuItem value={0}>Remove</MenuItem>}

      {options.map((option, i) => (
        <MenuItem key={i} value={option}>{option}</MenuItem>
      ))}

      {other.value && options.indexOf(other.value) === -1 && <MenuItem value={other.value}>{other.value}</MenuItem>}
    </Select>
  )
}
